import styled from "styled-components";

const ProfileQuizStyles = styled.div`
  max-width: var(--form-max-width);
  margin: auto;
  // padding: 2rem 3rem;
  background-color: var(--white);
  box-shadow: 0 0 30px rgba(50, 50, 90, 0.1);

  --container-padding-vertical: 2rem;
  --container-padding-horizontal: 3rem;
  --container-padding: var(--container-padding-vertical)
    var(--container-padding-horizontal);

  .header {
    padding: var(--container-padding);
    text-align: center;
    background-color: var(--green);
    color: white;
  }

  .body {
    padding: 3rem;
  }
  .form {
    &__progress {
    }
    &__question {
    }
    &__answers {
      & table {
        width: 100%;
        margin: 4rem auto 2rem;

        & th {
          text-align: left;
        }

        & td {
          padding: 10px 0;

          &:first-child {
            width: 55%;
            padding-right: 5%;
          }
        }
      }
    }
    &__submit {
    }
  }

  .form__instructions {
    margin-bottom: 12px;
  }

  .form-body {
    display: block;
    margin-bottom: 4rem;

    & fieldset {
      padding: 0;
    }

    & .form-body__input-container {
      width: 47%;
      margin-top: 0.4rem;
      margin-bottom: 0.5rem;
      padding: 0.6rem 0;

      .form-body__input {
        padding-right: 1.2rem;
      }

      &:first-child:not(:last-child) {
        float: left;
        margin-right: 1.5%;
      }
      &:last-child:not(:first-child) {
        float: right;
        margin-left: 1.5%;
      }
    }

    & label {
      font-size: 0.7em;
      font-weight: bold;
      margin-bottom: 5px;
      display: inline-block;
      margin-bottom: 5px;
    }

    & input:not([type="radio"]) {
      // & input {
      font-size: 1.5rem;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-color: #f5f8fa;
      color: #33475b;
      line-height: 1.375;
      border: 1px solid;
      border-color: #cbd6e2;
      border-radius: 0.1875rem;
      width: 100%;
      min-height: 2.5rem;
      transition: all 0.15s ease-out;
      padding: 0.6rem;

      // padding: 0.5625rem 0.625rem;

      // width: 100% !important;
      // font-size: 0.8rem;
      // min-height: 2rem;
    }
  }
  .full-width-input {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .question-submit-button {
    background-color: var(--green);
    color: white;
  }

  .footer {
    padding: var(--container-padding);
    background-color: var(--off-white);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.8em;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
  }

  .footer__notes {
    font-size: 0.7em;
    overflow: hidden;
    padding: 0 var(--container-padding-horizontal);
    max-height: 0;
    transition: all 0.3s ease;
    user-select: none;
    opacity: 0.75;

    &.open {
      padding: var(--container-padding);
      max-height: 100px;
    }
  }
`;

export default ProfileQuizStyles;

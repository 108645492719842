import React, { useState } from "react";
import messageSentSvg from "../assets/images/undraw_message_sent.svg";
import styled from "styled-components";
import { Link } from "gatsby";
import contactUsSvg from "../assets/images/undraw_contact_us.svg";
import moneyTree from "../assets/images/money_tree.jpg";

// icons
import { ImPhone } from "react-icons/im";
import { IoMdChatbubbles } from "react-icons/io";
import { FaCalendar } from "react-icons/fa";
import { FiChevronsDown } from "react-icons/fi";
import ProfileQuiz from "../components/ProfileQuiz";

const breakpointMaxWidth = "750px";

const StyledPageContainer = styled.div`
  min-height: 100%;

  .contact__hero {
    position: relative;
    margin-bottom: clamp(25px, 10vw, 100px);
  }

  .hero__text {
    width: 80%;
    margin: 0 auto;
    color: var(--dark-blue);
    background-color: #fff;
    padding: 3rem;
    border-radius: 4px;
    z-index: 2;
    text-align: center;
    box-shadow: var(--box-shadow);

    // @media (max-width: ${breakpointMaxWidth}) {
    //   width: auto;
    // }
  }

  .hero__title {
    font-size: clamp(2rem, 2.5vw, 3rem);
    font-weight: bold;
  }
  .hero__subtitle {
    margin-top: 24px;
    font-size: clamp(1.4rem, 1.5vw, 2rem);
  }

  .hero-image-container {
    text-align: center;
  }

  .hero-image-container__image {
    width: 75%;
    margin-top: 25px;
  }

  .contact-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    margin-bottom: 50px;
  }
  .contact-cards__card {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: var(--off-white);
    background-color: var(--white);
    z-index: 2;
    text-align: center;
    border-radius: 4px;
    box-shadow: var(--box-shadow-sm);

    margin: 0 10px;
    padding: 48px;

    &:first-child {
      margin-left: -20px;
    }
    &:last-child {
      margin-right: -20px;
    }
  }

  .card__icon {
    color: var(--gray);
    opacity: 0.75;
    font-size: 2em;
  }
  .card__title {
    margin-top: 2rem;
    margin-bottom: 1.3rem;
    font-size: 1.2em;
    font-weight: bold;
  }
  .card__extra {
  }

  .chevrons-pagebreak {
    font-size: 48px;
    text-align: center;
    width: 100%;
    color: var(--gray);
    margin-bottom: 50px;
  }

  @media (max-width: ${breakpointMaxWidth}) {
    .contact-cards {
      flex-direction: column;
      margin-top: 25px;
    }

    .contact-cards__card {
      margin: 10px 0;

      &:first-child,
      &:last-child {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
`;

export default function ContactPage({ navigate }) {
  return (
    <StyledPageContainer className="page-wrapper">
      <div className="contact__hero">
        <div className="hero__text">
          <h1 className="hero__title">Get To Know Us</h1>
          <div className="hero__subtitle">
            We'd love to help you live a more comfortable life, from finding
            policies that suit you better to helping you save a little extra for
            an easy retirement. Give us a call and see why we are a trusted
            partner to many happy clients!
          </div>
        </div>

        <div className="hero-image-container">
          <img
            src={messageSentSvg}
            alt="blurred background with stacks of coins with plants growing out of them"
            className="hero-image-container__image"
          />
        </div>
      </div>

      <div className="contact-cards">
        <div className="contact-cards__card">
          <div>
            <ImPhone className="card__icon" />
          </div>
          <div className="card__title">Call us directly</div>
          <div className="card__extra">
            <a href="tel:3307057994">330-705-7994</a>
          </div>
        </div>
        <div className="contact-cards__card">
          <div>
            <IoMdChatbubbles className="card__icon" />
          </div>
          <div className="card__title">
            Answer a few questions to get matched with an advisor
          </div>
          <div className="card__extra">
            <button className="themed" onClick={() => navigate("/upload")}>
              Find an advisor
            </button>
          </div>
        </div>
        <div className="contact-cards__card">
          <div>
            <FaCalendar className="card__icon" />
          </div>
          <div className="card__title">Book an appointment</div>
          <div className="card__extra">
            <button
              disabled
              className="themed"
              style={{ opacity: 0.5, pointerEvents: "none" }}
            >
              Choose a date & time
            </button>
          </div>
        </div>
      </div>

      <div>
        <FiChevronsDown className="chevrons-pagebreak" />
      </div>
    </StyledPageContainer>
  );
}
